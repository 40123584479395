import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';

import {GET_LIST, provider } from '../../Utils/_services/provider';

import Schedules from './Schedules';

export const styles = theme => createStyles({
    button: {
        marginLeft: theme.spacing(1),
    },
    schedule: {
        color: '#0a529f',
        backgroundColor: '#bbd9ea63',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#bbd9ea',
            border: '1px solid #0a529f',
        }
    },
})  

class SchedulesButton extends Component {
    state = {
        openSchedule: false, 
        schedulesList: null
    };

    openSchedule = async () => {
        this.setState({openSchedule: true})
        const { formValues } = this.props;
        let analysis = [];
        let city = null;
        let sex = null;
        if(this.props.data){
            analysis =  this.props.data.analysis;
            city =  this.props.data.city;
            sex = this.props.data.sex;
        }else{
            analysis =  formValues.analysis;
            city =  formValues.city;
            sex = formValues.sex;
        }
        const { data } = await provider(
            GET_LIST,
            '/schedules',
            {
                analysis: JSON.stringify(analysis),
                cityId: city,
                sex: sex
            }
        );
        this.setState({schedulesList: data})
    }

    handleClose = () => {
        this.setState({ openSchedule: false, schedulesList: null})
    }

    applications (data = []){
        let apps=[];
        for (var i = 0; i < data.length; i++) {
            if(apps.indexOf(data[i].application)<0 && data[i].application){
                apps.push(data[i].application);
            }
        }
        return apps;
    }

    render() {
        const {classes, formValues, disabled, data } = this.props;
        const { openSchedule, schedulesList } = this.state;
        let applications = [];
        let city = null;
        if(data){
            applications = this.applications(data.data)
            city = data.city;
        }else if(formValues){
            applications = this.applications(formValues.data);
            city = formValues.city;
        }
        return (
            <Fragment>
                <Button    
                    className={classes.button}  
                    classes={{
                        // root: classes.schedule
                    }}                                                   
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={applications.length===0 || !city || disabled}
                    startIcon={<ScheduleIcon />}
                    onClick={this.openSchedule} 
                >
                    График
                </Button>
                <Schedules 
                    open={openSchedule}
                    maxWidth="xl"
                    fullWidth={true}
                    list={schedulesList}
                    onClose={this.handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                />
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(SchedulesButton);
